import { createWebHistory, createRouter } from "vue-router";

import HomeThreePage from "../components/Pages/HomeThreePage";
import AboutPage from "../components/Pages/AboutPage";
import AuthorGuide from "../components/Pages/AuthorGuide";
import ErrorPage from "../components/Pages/ErrorPage";
import VolumeDetails from "../components/Pages/VolumeDetails.vue";
import ContactUsPage from "../components/Pages/ContactUsPage";

const routes = [
  { path: "/", name: "HomeOnePage", component: HomeThreePage },
  { path: "/about", name: "AboutPage", component: AboutPage },
  { path: "/author-guide", name: "AuthorGuide", component: AuthorGuide },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  {
    path: "/volumes/:id",
    name: "VolumeDetails",
    component: VolumeDetails,
  },
  { path: "/contact", name: "ContactUsPage", component: ContactUsPage },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
