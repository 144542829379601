import Vuex from 'vuex';
import axios from './axios';


export default new Vuex.Store({
    state: {
        url: null,
        journal: null,
        hero: null,
        faq: [],
        briefAbout: null,
        about: null,
        reviewers: [],
        volumes: [],
        authorGuide: null,
        loading: false,
    },
    mutations: {
        setUrl(state, data) {
            state.url = data;
        },
        setJournal(state, data) {
            state.journal = data;
        },
        setHero(state, data) {
            state.hero = data;
        },
        setFaq(state, data) {
            state.faq = data;
        },
        setBriefAbout(state, data) {
            state.briefAbout = data;
        },
        setAbout(state, data) {
            state.about = data;
        },
        setReviewers(state, data) {
            state.reviewers = data;
        },
        setVolumes(state, data) {
            state.volumes = data;
        },
        setAuthorGuide(state, data) {
            state.authorGuide = data;
        },
        setLoading(state, status) {
            state.loading = status;
        },
    },
    actions: {
        fetchDataFromApi({ commit }) {
            commit('setLoading', true);
            axios.get('/get-data')
                .then(response => {
                    commit('setUrl', response.data.base_url);
                    commit('setJournal', response.data.journal);
                    commit('setHero', response.data.hero);
                    commit('setFaq', response.data.faq);
                    commit('setBriefAbout', response.data.brief_about);
                    commit('setAbout', response.data.about);
                    commit('setReviewers', response.data.reviewers);
                    commit('setVolumes', response.data.volumes);
                    commit('setAuthorGuide', response.data.author_guide);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        },
    },
});