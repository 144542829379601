<template>
    <div class="blog-wrap pt-100 pb-70 bg-concrete" v-if="$store.state.volumes != null && $store.state.volumes.length > 0">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
                <h2>Our Volumes</h2>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-4 col-lg-6 col-md-6" v-for="volume in $store.state.volumes" :key="volume.id">
                <div class="blog-card style1">
                  <div class="blog-info">
                    <h3><router-link :to="'/volumes/' + volume.id">{{volume.title}}</router-link></h3>
                    <ul class="blog-metainfo  list-style">
                      <li><i class="flaticon-calendar"></i>{{new Date(volume.volume_date).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}}</li>
                    </ul>
                    <p>{{volume.small_description}}</p>
                    <router-link :to="'/volumes/' + volume.id" class="link style1">
                      Read More
                      <i class="flaticon-right-arrow"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurVolumes'
}
</script>