<template>
  <footer class="footer-wrap bg-rock" v-if="$store.state.journal != null">
    <div class="container">
      <div class="row pt-100 pb-75">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="footer-widget">
            <p class="comp-desc">
              {{$store.state.journal.description}}
            </p>
            <div class="social-link">
              <ul class="social-profile list-style style1">
                <li>
                  <a target="_blank" :href="$store.state.journal.facebook">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" :href="$store.state.journal.twitter">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" :href="$store.state.journal.linkedin">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Shortcuts</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/about">
                  <i class="flaticon-next"></i>
                  About
                </router-link>
              </li>
              <li>
                <router-link to="/contact">
                  <i class="flaticon-next"></i>
                  Contact
                </router-link>
              </li>
              <li>
                <router-link to="/author-guide">
                  <i class="flaticon-next"></i>
                  Author Guide
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6" v-if="$store.state.volumes != null && $store.state.volumes.length > 0">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Volumes</h3>
            <ul class="footer-menu list-style">
              <li v-for="volume in $store.state.volumes" :key="volume.id">
                <router-link :to="'/volumes/' + volume.id">
                  <i class="flaticon-next"></i>
                  {{ volume.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">{{ $store.state.journal.name }}</h3>
            <p class="newsletter-text">
              ISSN: {{ $store.state.journal.issn }}
              <br>
              E-ISSN: {{ $store.state.journal.e_issn }}
            </p>
            <p>
              Copyright &copy; {{currentYear}} {{$store.state.journal.name}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
