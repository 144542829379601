<template>
    <div class="contact-wrap">
        <div class="contact-form-area ptb-100 bg-albastor">
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                        <div class="content-title style1 text-center mb-40">
                            <span>Send Us A Message</span>
                            <h2>Do You have Any Questions?</h2>
                        </div>
                        <div class="contact-form">
                            <form class="form-wrap" v-on:submit.prevent="submitForm">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="name" placeholder="Your Name*" id="name" v-model="formData.fullName"
                                                required data-error="Please enter your name">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" required v-model="formData.email"
                                                placeholder="Your Email*" data-error="Please enter your email*">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="number" name="phone" id="phone" required v-model="formData.phone"
                                                placeholder="Phone Number" data-error="Please enter your phone number">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="msg_subject" placeholder="Subject" v-model="formData.subject"
                                                id="msg_subject" required data-error="Please enter your subject">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group v1">
                                            <textarea name="message" id="message" placeholder="Your Messages.." v-model="formData.message"
                                                cols="30" rows="10" required
                                                data-error="Please enter your message"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-center">
                                      <button type="submit" class="btn style1 w-100 d-block">Send Message <b-spinner v-if="isLoading" class="mx-2" variant="white" label="Spinning" small></b-spinner></button>
                                    </div>
                                  <div class="mt-3">
                                    <b-alert show dismissible v-if="showSuccess">
                                      Your message sent successfully
                                    </b-alert>
                                    <b-alert show dismissible v-if="showError" variant="warning">
                                      Sending your message is failed, please try again!
                                    </b-alert>
                                  </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '../../axios';
export default {
  name: 'Contact',
  methods: {
    submitForm: function () {
      this.showSuccess = false;
      this.showError = false;
      this.isLoading = true;
      const formData = new FormData();
      formData.append('full_name', this.formData.fullName);
      formData.append('email', this.formData.email);
      formData.append('message', this.formData.message);
      formData.append('phone', this.formData.phone);
      formData.append('subject', this.formData.subject);

      axios.post('contact', formData)
          .then(response => {
            this.isLoading = false;
            console.log('Server response:', response.data);
            this.formData.fullName = '';
            this.formData.email = '';
            this.formData.message = '';
            this.formData.phone = '';
            this.formData.subject = '';
            this.showSuccess = true;
            this.showError = false;
          })
          .catch(error => {
            this.isLoading = true;
            this.showError = true;
            this.showSuccess = false;
            console.error('Error sending data to server:', error);
          });
    },
  },
  data: () => ({
    formData: {
      fullName: '',
      email: '',
      subject: '',
      phone: '',
      message: '',
    },
    isLoading: false,
    showSuccess: false,
    showError: false,
  }),
}
</script>