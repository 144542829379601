<template>
  <div class="hero-wrap style1 bg-spring" v-if="$store.state.hero != null">
    <div class="hero-slider-two">
      <div class="hero-slide-item">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="hero-content">
                <span>{{ $store.state.hero.small_title }}</span>
                <h1>{{ $store.state.hero.title }}</h1>
                <p>{{ $store.state.hero.description }}</p>
                <router-link to="/" class="btn style1">Get Started</router-link>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="hero-img-wrap">
                <img :src="$store.state.url + $store.state.hero.image" alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "MainBanner",
  components: {},
  data: () => ({

  }),
});
</script>
