<template>
    <div class="about-wrap style1 ptb-100" v-if="$store.state.briefAbout != null">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="about-img-wrap">
                        <img :src="$store.state.url + $store.state.briefAbout.image" alt="Image" class="about-img">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-content">
                        <img src="../../assets/images/about/about-shape-3.png" alt="Image" class="about-shape-three">
                        <div class="content-title style1">
                            <span>{{$store.state.briefAbout.small_title}}</span>
                            <h2>{{$store.state.briefAbout.title}}</h2>
                            <p>{{$store.state.briefAbout.description}}</p>
                        </div>
                        <ul class="content-feature-list style1 list-style" v-if="$store.state.briefAbout.aims != null">
                            <li v-for="item in $store.state.briefAbout.aims.split(';')" :key="item"><span><i class="flaticon-tick"></i></span>{{item}}</li>
                        </ul>
                        <router-link to="/about" class="btn style1">More About us</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BriefUs'
}
</script>