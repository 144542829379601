<template>
  <div class="service-details-wrap ptb-100" v-if="$store.state.about != null">
    <div class="container">
      <div class="row gx-5">
        <div class="col-xl-8 col-lg-12">
          <div class="service-desc">
            <lightgallery
                :settings="{ speed: 500, plugins: plugins }"
                :onInit="onInit"
                :onBeforeSlide="onBeforeSlide"
            >
              <div class="service-img mb-25 d-block">
                <img className="img-responsive" :src="$store.state.url + $store.state.about.image" />
              </div>
            </lightgallery>
            <div class="container" v-html="$store.state.about.description"></div>
            <h2>Frequently Asked Question</h2>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item" v-for="faq in $store.state.faq" :key="faq.id">
                <h2 class="accordion-header" :id="'headingOne' + faq.id">
                  <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#collapseOne' + faq.id"
                      aria-expanded="true"
                      :aria-controls="'collapseOne' + faq.id"
                  >
                    <span>
                      <i class="ri-arrow-down-s-line plus"></i>
                      <i class="ri-arrow-up-s-line minus"></i>
                    </span>
                    {{ faq.question }}
                  </button>
                </h2>
                <div
                    :id="'collapseOne' + faq.id"
                    class="accordion-collapse collapse show"
                    :aria-labelledby="'headingOne' + faq.id"
                    data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div class="single-product-text">
                      <p>
                        {{ faq.answer }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-12" v-if="$store.state.about.aims != null">
          <div class="sidebar">
            <div class="sidebar-widget style2">
              <h4>Our Main Subjects</h4>
              <div class="category-box style2">
                <ul class="list-style">
                  <li v-for="item in $store.state.about.aims.split(';')" :key="item.id">
                    <router-link to="">
                      {{item}}
                      <span><i class="flaticon-next"></i></span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Lightgallery from "lightgallery/vue";
import lgZoom from "lightgallery/plugins/zoom";
let lightGallery = null;

export default {
  name: "AboutComponent",
  components: {
    Lightgallery,
  },
  data: () => ({
    plugins: [lgZoom],
  }),
  methods: {
    onInit: (detail) => {
      lightGallery = detail.instance;
    },
    onBeforeSlide: () => {
      console.log("calling before slide");
    },
  },
};
</script>
<style lang="css">
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lightgallery.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lg-zoom.css");
</style>
