<template>
    <div class="account-wrap ptb-80" v-if="$store.state.authorGuide != null">
        <div class="container" v-html="$store.state.authorGuide.guide"></div>
    </div>
</template>

<script>
export default {
    name: 'AuthorGuideDescription'
}
</script>