<template>
    <div class="page-wrapper">
        <Navbar class="style1" />
        <MainBanner />
        <BriefUs />
        <OurCommitie />
        <OurVolumes />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../HomeThree/MainBanner'
import BriefUs from '../HomeThree/BriefUs'
import OurCommitie from '../HomeThree/OurCommitie'
import OurVolumes from '../HomeThree/OurVolumes'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomeThreePage',
    components: {
      Navbar,
      MainBanner,
      BriefUs,
      OurCommitie,
      OurVolumes,
      Footer,
    }
}
</script>