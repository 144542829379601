<template>
    <div class="page-wrapper">
        <Navbar class="style1" />
        <div class="content-wrapper">
            <AuthorGuideDescription />
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import AuthorGuideDescription from '../Description/AuthorGuideDescription.vue'
import Footer from '../Layouts/Footer'

export default {
    name: 'AuthorGuide',
    components: {
      Navbar,
      AuthorGuideDescription,
      Footer,
    }
}
</script>