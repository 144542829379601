<template>
    <div class="page-wrapper" v-if="$store.state.volumes != null && $store.state.volumes.length > 0">
        <Navbar class="style1" />
        <div class="content-wrapper">
          <div class="blog-details-wrap ptb-100">
            <div class="container">
              <div class="row gx-5">
                <div class="col-xl-12 col-lg-12">
                  <div>
                    <ul class="post-metainfo  list-style">
                      <li><i class="flaticon-calendar"></i>{{new Date($store.state.volumes.filter((element) => element.id == this.$route.params.id)[0].volume_date).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}}</li>
                    </ul>
                    <h2>{{ $store.state.volumes.filter((element) => element.id == this.$route.params.id)[0].title }}</h2>
                    <div class="post-para" v-html="$store.state.volumes.filter((element) => element.id == this.$route.params.id)[0].description"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Footer from '../Layouts/Footer'

export default {
    name: 'VolumeDetails',
    components: {
      Navbar,
      Footer,
    },
}
</script>