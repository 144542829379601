<template>
    <div class="page-wrapper">
        <Navbar class="style1" />
        <div class="content-wrapper">
            <AboutComponent />
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Footer from '../Layouts/Footer'
import AboutComponent from "@/components/About/َAboutComponent";

export default {
    name: 'AboutPage',
    components: {
      Navbar,
      Footer,
      AboutComponent
    }
}
</script>