<template>
  <div :class="['header-wrap', { sticky: isSticky }]" v-if="$store.state.journal != null">
    <!-- Top Header -->
    <div class="header-top" :class="{ open: open }">
      <button type="button" class="close-sidebar" @click="open = !open">
        <i class="ri-close-fill"></i>
      </button>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
            <div class="header-top-left">
              <ul class="contact-info list-style">
                <li>
                  <i class="flaticon-call"></i>
                  <a :href="$store.state.journal.phone">{{$store.state.journal.phone}}</a>
                </li>
                <li>
                  <i class="flaticon-email-1"></i>
                  <a :href="$store.state.journal.email">{{$store.state.journal.email}}</a>
                </li>
                <li>
                  <i class="flaticon-pin"></i>
                  <p>{{$store.state.journal.address}}</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-3">
            <div class="header-top-right">
              <ul class="header-top-menu list-style">
                <li>
                  <a :href="$store.state.journal.facebook"><i class="flaticon-facebook"></i></a>
                </li>
                <li>
                  <a :href="$store.state.journal.twitter"><i class="flaticon-twitter"></i></a>
                </li>
                <li>
                  <a :href="$store.state.journal.linkedin"><i class="flaticon-linkedin"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Header -->

    <!-- Navbar -->
    <div class="header-bottom">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link class="navbar-brand" to="/">
            <img
              class="logo-light"
              :src="$store.state.url + $store.state.journal.logo"
              alt="logo"
              width="200"
            />
            <img
              class="logo-dark"
              :src="$store.state.url + $store.state.journal.logo"
              alt="logo"
            />
          </router-link>

          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <router-link to="/" class="nav-link">Home</router-link>

              </li>

              <li class="nav-item">
                <router-link to="/about" class="nav-link">About</router-link>
              </li>

              <li class="nav-item has-dropdown">
                <a href="/author-guide" class="nav-link">
                  Guide for Authors
                </a>
              </li>

              <li class="nav-item">
                <router-link to="/contact" class="nav-link">Contact</router-link>
              </li>
            </ul>

          </div>

          <div class="mobile-bar-wrap">
            <div
              class="mobile-sidebar"
              @click="open = !open"
              :aria-pressed="open ? 'true' : 'false'"
              v-bind:class="{ open: button_open_state }"
              v-on:click="button_open_state = !button_open_state"
            >
              <i class="ri-menu-4-line"></i>
            </div>
            <button
              class="searchbtn"
              type="button"
              @click="search = !search"
              :aria-pressed="search ? 'true' : 'false'"
              v-bind:class="{ search: button_search_state }"
              v-on:click="button_search_state = !button_search_state"
            >
              <i class="flaticon-search"></i>
            </button>
            <div
              class="navbar-toggler mobile-menu xl-none"
              @click="active = !active"
              :aria-pressed="active ? 'true' : 'false'"
              v-bind:class="{ active: button_active_state }"
              v-on:click="button_active_state = !button_active_state"
            >
              <a href="javascript:void(0);">
                <i class="ri-menu-line"></i>
                <i class="ri-close-line"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>

      <!-- Search Modal -->
      <div class="search-area" :class="{ search: search }">
        <div class="container">
          <form @submit.prevent>
            <div class="form-group">
              <input type="search" placeholder="Search Here" autofocus />
            </div>
          </form>
          <button
            type="button"
            class="close-searchbox"
            @click="search = !search"
          >
            <i class="ri-close-line"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- End Navbar -->
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    isSticky: false,
    active: false,
    button_active_state: false,
    search: false,
    button_search_state: false,
    open: false,
    button_open_state: false,
  }),
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
};
</script>
